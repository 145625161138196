


















































































































































































































.el-input {
  margin-bottom: 5px;
}

.upload-file {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  .el-upload-list {
    flex-shrink: 0;
    width: 100%;
  }
}

.sharing-form {
  padding: 15px 30px 0;
  font-size: 21px;
  font-weight: 200;
  line-height: 2.1428571435;
  color: inherit;
  background-color: #eee;
  margin-bottom: 30px;
}

.btn {
  border: none;

  &.yellow {
    color: #000;

    svg {
      margin-left: -5px;
      margin-right: 5px;
    }
  }
}

.tree-item-name {
  padding-left: 5px;
}

.action {
  padding: 3px;
  margin: 0 5px;
}

.pull-right {
  display: flex;
}
